import './App.css';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import linguatechLogoImage from './linguatech-logo.png'; 
import desarrolladorImage from './desarrollador-linguatech.jpg'; 
import linguatechLogoBlueImage from './linguatech-logo-blue.png'; 
import santiagoLozanoPic from './santiagoLozanoPic.jpeg'; 
import desarrolladorIngles from './desarrollador-ingles.jpg'; 
import unavailableSystemImage from './unavailableSystem.jpg'; 
import davidPic from './davidPic.jpg'; 
import sebastianPic from './sebastianPic.jpg'; 
import fotoFounder from './foto-founder.png'; 
import audio1 from './audio1.mp3'; 
import exlicacionPresentePerfectoAudio from './explicacion-presente-perfecto-linguatech.mp3'; 
import utilizacionPresentePerfectoAudio from './utilizacion-presente-perfecto-linguatech 2.mp3'; 
import ejemploPresentePerfectoAudio from './ejemplo-presente-perfecto-linguatech.mp3'; 
import ejercicioPresentePerfectoAudio from './ejercicio-presente-perfecto-linguatech.mp3'; 
import saludoPresentePerfectoAudio from './saludo-presente-perfecto-linguatech.mp3'; 
import { LuBookOpen } from "react-icons/lu";
import { MdOutlineAttachMoney } from "react-icons/md";
import { LuCalendarClock } from "react-icons/lu";
import { PiInfinityBold } from "react-icons/pi";
import { VscEye } from "react-icons/vsc";
import { FaCheck } from "react-icons/fa";
import { MdRecordVoiceOver } from "react-icons/md";
import { MdGroups } from "react-icons/md";
import { DiScrum } from "react-icons/di";
import { GrSwim } from "react-icons/gr";
import { GiProgression } from "react-icons/gi";
import { GiArtificialHive } from "react-icons/gi";
import {  FaPause } from 'react-icons/fa';
import { PiPlayPauseFill } from "react-icons/pi";
import { RiVoiceprintLine } from "react-icons/ri";
import axios from 'axios';

/*Colores #28d1dd #1aabdb #f6f9f9 #187fda #2f63dc  
Tipografía: Exo 2, Telegraf, Open Sauce
background del logo azul: #2F63DC 
background del logo blanco: #FFFFFF 
*/


function App() {
  const [showSuscriptionModal, setShowSuscriptionModal] = useState(false);
  const [showSuscriptionForgivenesssModal, setShowSuscriptionForgivenesssModal] = useState(false);
  const [showPersonalInformationPoliticModal, setShowPersonalInformationPoliticModal] = useState(false);
  const [showSuscriptionFailedModal, setShowSuscriptionFailedModal] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [checkedPersonalInformationPolitic, setCheckedPersonalInformationPolitic] = useState(false);
  const [isPlayingAudio1, setIsPlayingAudio1] = useState(false);
  const [isPlayingAudio2, setIsPlayingAudio2] = useState(false);
  const [isPlayingAudio3, setIsPlayingAudio3] = useState(false);
  const [isPlayingAudio4, setIsPlayingAudio4] = useState(false);
  const [isPlayingAudio5, setIsPlayingAudio5] = useState(false);

  const [nameIsEmpty, setNameIsEmpty] = useState(false);
  const [nameIsIncorrect, setNameIsIncorrect] = useState(false);
  const [passwordIsEmpty, setPasswordIsEmpty] = useState(false);
  const [passwordIsIncorrect, setPasswordIsIncorrect] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [phoneIsEmpty, setPhoneIsEmpty] = useState(false);
  const [phoneIsIncorrect, setPhoneIsIncorrect] = useState(false);
  const [phoneExisting, setPhoneExisting] = useState(false);
  const [emailIsEmpty, setEmailIsEmpty] = useState(false);
  const [emailIsIncorrect, setEmailIsIncorrect] = useState(false);
  const [emailExisting, setEmailExisting] = useState(false);
  const [politicIsEmpty, setPoliticIsEmpty] = useState(false);
  const urlCreateUser = 'https://linguatech-user.azurewebsites.net/api/v1/user?code=S1LAv6Ama8fsYhSK0sJRxXgttor4yph2V1UylcuC56K7AzFurkRWKw==';

  
  const handleCloseSuscriptionModal = () => {
    setNameIsEmpty(false) 
    setPasswordIsEmpty(false)
    setEmailIsEmpty(false) 
    setPhoneIsEmpty(false)
    setPoliticIsEmpty(false)
    setShowSuscriptionModal(false);
  }

  const scrollToSuscriptionSection = () => {
    const pricingSection = document.getElementById('pricing-section');
    const yOffset = -100; // Distancia en píxeles para detenerse antes del elemento
    const y = pricingSection.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({
      top: y,
      behavior: 'smooth'
    });
  };

  const togglePlayPauseAudio1 = () => {
    const audio = document.getElementById('myAudio1');
    const audio2 = document.getElementById('myAudio2');
    const audio3 = document.getElementById('myAudio3');
    const audio4 = document.getElementById('myAudio4');
    const audio5 = document.getElementById('myAudio5');
    audio2.pause();
    audio3.pause();
    audio4.pause();
    audio5.pause();
    setIsPlayingAudio2(false);
    setIsPlayingAudio3(false);
    setIsPlayingAudio4(false);
    setIsPlayingAudio5(false);
    if (audio.paused) {
      audio.play();
      setIsPlayingAudio1(true);
    } else {
      audio.pause();
      setIsPlayingAudio1(false);
    }
  }

  const togglePlayPauseAudio2 = () => {
    const audio = document.getElementById('myAudio2');
    const audio1 = document.getElementById('myAudio1');
    const audio3 = document.getElementById('myAudio3');
    const audio4 = document.getElementById('myAudio4');
    const audio5 = document.getElementById('myAudio5');
    audio1.pause();
    audio3.pause();
    audio4.pause();
    audio5.pause();
    setIsPlayingAudio1(false);
    setIsPlayingAudio3(false);
    setIsPlayingAudio4(false);
    setIsPlayingAudio5(false);
    if (audio.paused) {
      audio.play();
      setIsPlayingAudio2(true);
    } else {
      audio.pause();
      setIsPlayingAudio2(false);
    }
  }
  
  const togglePlayPauseAudio3 = () => {
    const audio = document.getElementById('myAudio3');
    const audio2 = document.getElementById('myAudio2');
    const audio1 = document.getElementById('myAudio1');
    const audio4 = document.getElementById('myAudio4');
    const audio5 = document.getElementById('myAudio5');
    audio2.pause();
    audio1.pause();
    audio4.pause();
    audio5.pause();
    setIsPlayingAudio1(false);
    setIsPlayingAudio2(false);
    setIsPlayingAudio4(false);
    setIsPlayingAudio5(false);
    if (audio.paused) {
      audio.play();
      setIsPlayingAudio3(true);
    } else {
      audio.pause();
      setIsPlayingAudio3(false);
    }
  }

  
  const togglePlayPauseAudio4 = () => {
    const audio = document.getElementById('myAudio4');
    const audio2 = document.getElementById('myAudio2');
    const audio3 = document.getElementById('myAudio3');
    const audio1 = document.getElementById('myAudio1');
    const audio5 = document.getElementById('myAudio5');
    audio2.pause();
    audio3.pause();
    audio1.pause();
    audio5.pause();
    setIsPlayingAudio1(false);
    setIsPlayingAudio2(false);
    setIsPlayingAudio3(false);
    setIsPlayingAudio5(false);
    if (audio.paused) {
      audio.play();
      setIsPlayingAudio4(true);
    } else {
      audio.pause();
      setIsPlayingAudio4(false);
    }
  }

  
  const togglePlayPauseAudio5 = () => {
    const audio = document.getElementById('myAudio5');
    const audio2 = document.getElementById('myAudio2');
    const audio3 = document.getElementById('myAudio3');
    const audio4 = document.getElementById('myAudio4');
    const audio1 = document.getElementById('myAudio1');
    audio2.pause();
    audio3.pause();
    audio4.pause();
    audio1.pause();
    setIsPlayingAudio1(false);
    setIsPlayingAudio2(false);
    setIsPlayingAudio3(false);
    setIsPlayingAudio4(false);
    if (audio.paused) {
      audio.play();
      setIsPlayingAudio5(true);
    } else {
      audio.pause();
      setIsPlayingAudio5(false);
    }
  }


  const handleShowSuscriptionModal = () => setShowSuscriptionModal(true);

  const handleClosPersonalInformationPoliticModal = () => {
    setShowPersonalInformationPoliticModal(false);
    handleShowSuscriptionModal()
  }

  const handleShowPersonalInformationPoliticModal = () => {
    handleCloseSuscriptionModal()
    setShowPersonalInformationPoliticModal(true);
  }

  const aceptPersonalInformationPolitic = () => {
    handleClosPersonalInformationPoliticModal()
    handleShowSuscriptionModal()
    setCheckedPersonalInformationPolitic(true)
  }

  const existEmptyData = () => {
    userName === '' ? setNameIsEmpty(true) : setNameIsEmpty(false); 
    userPassword === '' ? setPasswordIsEmpty(true) : setPasswordIsEmpty(false);
    userEmail === '' ? setEmailIsEmpty(true) : setEmailIsEmpty(false); 
    userPhone === '' ? setPhoneIsEmpty(true) : setPhoneIsEmpty(false);
    checkedPersonalInformationPolitic === false ?  setPoliticIsEmpty(true) : setPoliticIsEmpty(false);
   
    return (userName === '' || userPassword === ''  || userEmail === '' || userPhone === '' ||
    checkedPersonalInformationPolitic === false ) ? true : false;
  }

  const existIncorrectData = () => {
    var nameRegex = /^[a-zA-Z\s]+$/;
    var emailRegex = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;
    var phoneRegex = /^[0-9]{10}$/;
    var passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$/; 
    var response = false;

    if(nameRegex.test(userName)){
      setNameIsIncorrect(false);
    } else{
      setNameIsIncorrect(true);
      response = true;
    }

    if(emailRegex.test(userEmail)){
      setEmailIsIncorrect(false);
    } else{
      setEmailIsIncorrect(true);
      response = true;
    }
    if(phoneRegex.test(userPhone)){
      setPhoneIsIncorrect(false);
    } else{
      setPhoneIsIncorrect(true);
      response = true;
    }
    if(passwordRegex.test(userPassword)){
      setPasswordIsIncorrect(false);
    } else{
      setPasswordIsIncorrect(true);
      response = true;
    }
    return response;
  }

  const cleanInputs = () => {
    setUserName('');
    setUserEmail('');
    setUserPhone('');
    setUserPassword('');
    setCheckedPersonalInformationPolitic(false);
  }

  const consumeCreateUsereService = (userData) => {
    axios.post(urlCreateUser, userData)
    .then(response => {
        setShowSuscriptionForgivenesssModal(true);
        handleCloseSuscriptionModal();
        cleanInputs()
    })
    .catch(error => {
        if(error.response) {
          if(error.response.status === 409) {
            if(error.response.data && error.response.data.detail) {
              if(error.response.data.detail.toLowerCase().includes('teléfono')) {
                setPhoneExisting(true)
              }
              if(error.response.data.detail.toLowerCase().includes('email')) {
                setEmailExisting(true)
              }
            }
          } 
        } else {
          setShowSuscriptionFailedModal(true)
          handleCloseSuscriptionModal();
          cleanInputs()
        }
    });
  } 

  const handleSubmitUserData = () => {
    setEmailExisting(false)
    setPhoneExisting(false)
    if( existEmptyData() === false && existIncorrectData() === false) {
      consumeCreateUsereService({name: userName, email: userEmail, phone: userPhone, password: convertTexToBase64(userPassword), acceptPersonalInformationPolicy: checkedPersonalInformationPolitic})
    }
  };

  const convertTexToBase64 = (text) => {
    return btoa(text);
  }


  useEffect(() => {
    const services = document.querySelectorAll('.service');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('service-enter');
        }
      });
    }, { threshold: 0.1 });
  
    services.forEach(service => {
      observer.observe(service);
    });
  
    const testimonials = document.querySelectorAll('.testimonial-card-parent');
    const observerTestimonials = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('testimonial-enter');
        }
      });
    }, { threshold: 0.1 });
  
    testimonials.forEach(testimonial => {
      observerTestimonials.observe(testimonial);
    });

    const audio1 = document.getElementById('myAudio1');
    const handleAudioEnd1 = () => setIsPlayingAudio1(false);
    audio1.addEventListener('ended', handleAudioEnd1);

    const audio2 = document.getElementById('myAudio2');
    const handleAudioEnd2 = () => setIsPlayingAudio2(false);
    audio2.addEventListener('ended', handleAudioEnd2);

    const audio3 = document.getElementById('myAudio3');
    const handleAudioEnd3 = () => setIsPlayingAudio3(false);
    audio3.addEventListener('ended', handleAudioEnd3);

    const audio4 = document.getElementById('myAudio4');
    const handleAudioEnd4 = () => setIsPlayingAudio4(false);
    audio4.addEventListener('ended', handleAudioEnd4);

    const audio5 = document.getElementById('myAudio5');
    const handleAudioEnd5 = () => setIsPlayingAudio5(false);
    audio5.addEventListener('ended', handleAudioEnd5);

    // Opcional: limpieza si el componente se desmonta
    return () => {
      services.forEach(service => {
        observer.unobserve(service);
      });
      testimonials.forEach(testimonial => {
        observer.unobserve(testimonial);
      });
      audio1.removeEventListener('ended', handleAudioEnd1);
      audio2.removeEventListener('ended', handleAudioEnd2);
      audio3.removeEventListener('ended', handleAudioEnd3);
      audio4.removeEventListener('ended', handleAudioEnd4);
      audio5.removeEventListener('ended', handleAudioEnd5);
    };

  }, []); 

  return (
    <div className="App">
      <Navbar fixed="top" style={{backgroundColor: "#2f63dc"}}>
          <Container className='container-navbar'>
            <Navbar.Brand href="./" style={{ display: "flex", alignItems: "center"}}>
               <img src={linguatechLogoImage} className='Logo-navbar'
                alt="Logo de Linguatech - Clases de Inglés para Desarrolladores de Software"/> 
            </Navbar.Brand>
            <button
              className="App-button-suscribe-header"
              onClick={scrollToSuscriptionSection}
              aria-label="Suscríbete a Linguatech"
            >
              Aprende Gratis
            </button>
          </Container>
      </Navbar>
      <header className="App-header">  
        {/* <h1 style={{marginBottom: "30px", marginTop: "10px"}}>Clases de Inglés diseñadas para desarrolladores de software</h1> */}
          <div className='hook'>
            <h1>Así es como los Desarrolladores de Software aprenden Inglés</h1>
            <p style={{textAlign: "left"}}>Clases de Inglés efocadas en el ámbito laboral, implementación de IA y sesiones individuales te ayudan a aprender el idioma y a deselvolverte en tus actividades cotidianas como programador.</p>
            <button className="App-button" style={{ float: "left" }} onClick={scrollToSuscriptionSection} aria-label='Suscríbete a Linguatech'>Aprende Gratis</button>
          </div>       
          <div className='hook-image'>
            <img src={desarrolladorImage} id='desarrollador-linguatech' alt="Programador - Clases de Inglés para Desarrolladores de Software"/> 
          </div>
      </header>
      <div className="presentation">  
          <div className='presentation-description'>
            <h2>Tu profesor personal con Inteligencia Artificial</h2>
            <p style={{textAlign: "left"}}>Con la implementación de la Inteligencia Artificial más avanzada del mercado puedes
              aprender inglés como si se tratase de un profesor nativo. {/*No podrás distinguir si es una persona o si se trata de una IA.*/}
              Participa en el número de clases que quieras, cuando quieras y sin restricciones. Nuestra IA siempre está dispuesta a ayudarte.</p>
          </div> 
          <div className='presentation-evidence-container'>
            <div className='presentation-evidence-list-icon'>

              <div className="audio-control" onClick={togglePlayPauseAudio1}>
                {isPlayingAudio1 ? <FaPause className="audio-icon-pause" /> : <PiPlayPauseFill className="audio-icon" />}
              </div>
              <audio id="myAudio1" src={saludoPresentePerfectoAudio} type="audio/mp3"></audio>

              <div className="audio-control" onClick={togglePlayPauseAudio2}>
                {isPlayingAudio2 ? <FaPause className="audio-icon-pause" /> : <RiVoiceprintLine className="audio-icon"/>}
              </div>
              <audio id="myAudio2" src={exlicacionPresentePerfectoAudio} type="audio/mp3"></audio>

              <div className="audio-control" onClick={togglePlayPauseAudio3}>
                {isPlayingAudio3 ? <FaPause className="audio-icon-pause" /> : <RiVoiceprintLine className="audio-icon" />}
              </div>
              <audio id="myAudio3" src={utilizacionPresentePerfectoAudio} type="audio/mp3"></audio>
            </div>
            <div className='presentation-evidence-list-icon'>
              <div className="audio-control" onClick={togglePlayPauseAudio4}>
                {isPlayingAudio4 ? <FaPause className="audio-icon-pause" /> : <RiVoiceprintLine className="audio-icon"/>}
              </div>
              <audio id="myAudio4" src={ejemploPresentePerfectoAudio} type="audio/mp3"></audio>

              <div className="audio-control" onClick={togglePlayPauseAudio5}>
                {isPlayingAudio5 ? <FaPause className="audio-icon-pause" /> : <RiVoiceprintLine className="audio-icon" />}
              </div>
              <audio id="myAudio5" src={ejercicioPresentePerfectoAudio} type="audio/mp3"></audio> 
            </div>
          </div>        
      </div>
    {/*   <div className="App-about-us">
        <h2>
          Linguatech es una academia diseñada especialmente para programadores latinos que buscan acelerar el aprendizaje y la fluides del inglés
        </h2>
        <p>
        Nos especializamos en un inglés adaptado para programadores, enseñando el lenguaje esencial que necesitan para dominar el idioma en el ámbito laboral. 
        No es el inglés cotidiano; es un inglés diseñado para que los desarrolladores de software se comuniquen con fluidez y alcancen oportunidades globales, potenciando
        sus ingresos en dólares.
        </p>
      </div>  */}
      <div className="services-section">
        <div className="service">
          <div className="icon-bg">
            <LuBookOpen aria-label="Icono de aprendizaje de inglés orientado a desarrolladores de softwatre" 
              color='blue' style={{fontSize: "2rem"}}></LuBookOpen> 
          </div>
          <h3 style={{marginBottom: "1rem"}}>Aprendizaje de inglés orientado a desarrolladores</h3>
          {/*<p>Ofrecemos lecciones de inglés estructuradas en torno al contexto laboral e intereses de cada programador.</p> */}
          <p>Ofrecemos lecciones de inglés estructuradas y enfocadas en el ámbito laboral para que te desenvuelvas en actividades comunes como: Dailies, Plannings, Reviews, Entrevistas y más.</p>
        </div>
        <div className="service">
          <div className="icon-bg">
            <LuCalendarClock  aria-label="Icono de aprendizaje de inglés en cualquier horario y cualquier momento" 
             color='blue' style={{fontSize: "2rem", borderWidth: "40px"}}></LuCalendarClock > 
          </div>
          <h3 style={{marginBottom: "1rem"}}>Inglés a tu ritmo, en cualquier momento</h3>
          <p>Nuestras clases simpre estarán disponbiles, permitiéndote aprender inglés cualquier día y a cualquier hora.</p>
        </div>
        <div className="service">
          <div className="icon-bg">
            <PiInfinityBold aria-label="Icono de clases de inglés sin límites ni rectricciones"
              color='blue' style={{fontSize: "2rem"}}></PiInfinityBold> 
          </div>
          <h3 style={{marginBottom: "1rem"}}>Asiste a tantas clases como desees, sin restricciones</h3>
          <p>Accede sin límites a nuestras clases individuales y personalizadas; tantas veces como lo necesites y sin costes adicionales.</p>
        </div>
      </div>
      <div className="features">  
          <div className='feature-image'>
            <img src={desarrolladorIngles} id="image-english-developer" 
              alt="Estudiante Linguatech - Clases de Inglés para Desarrolladores de Software"/> 
          </div>
          <div className='feature-items'>
            <h2>Aprende inglés y aplicalo directamente en tu rutina laboral</h2>
            <div style={{marginTop: "2rem"}}>
              <div className="icon-text-container">
                <div className="icon-bg-feature">
                  <MdOutlineAttachMoney aria-label="Icono de precio de Linguatech" 
                    color='blue' style={{fontSize: "2.5rem"}}></MdOutlineAttachMoney>
                </div>
                <div className="feature-text">
                  <h4 style={{ textAlign: "left" }}>Precios bajos</h4>
                  <p style={{fontSize: "1.1rem"}}>Nuestra modelo de negocio nos permite tener los precios más bajos del mercado.</p>
                </div>
              </div>
              <div className="icon-text-container">
                <div className="icon-bg-feature">
                  <MdRecordVoiceOver aria-label="Icono de clases en Linguatech" 
                    color='blue' style={{fontSize: "2.5rem"}}></MdRecordVoiceOver>
                </div>
                <div className="feature-text">
                  <h4>Inglés conversacional</h4>
                  <p style={{fontSize: "1.1rem"}}>Nuestra metodología prioriza las habildiades conversacionales para que te 
                    puedas comunicar con los miembros de tu equipo.</p>
                </div>
              </div>
              <div className="icon-text-container">
                <div className="icon-bg-feature">
                  <MdGroups aria-label="Icon de clases grupales Linguatech" 
                    color='blue' style={{fontSize: "2.5rem"}}></MdGroups>
                </div>
                <div className="feature-text">
                  <h4 style={{ textAlign: "left" }}>Prácticas grupales</h4>
                  <p style={{fontSize: "1.1rem"}}>Periódicamente coordinamos encuentros grupales para conectar con personas de tu mismo sector.</p>
                </div>
              </div>
              <div className="icon-text-container">
                <div className="icon-bg-feature">
                  <DiScrum aria-label="Icono de clases enfocadas en developers" 
                    color='blue' style={{fontSize: "2.5rem"}}></DiScrum>
                </div>
                <div className="feature-text">
                  <h4 style={{ textAlign: "left" }}>Simulaciones de Scrum</h4>
                  <p style={{fontSize: "1.1rem"}}>Realizamos actividades de Scrum como dailies, Reviews, y más,
                      para que aprendas a desenvolverte en dichas situaciones laborales.</p>
                </div>
              </div>
            </div>
          </div>       
      </div>
      <div className='aditional-features'>
          <div className="icon-text-container">
                <div className="icon-bg-feature">
                  <GiProgression  aria-label="Icono de niveles de Linguatech" 
                    color='blue' style={{fontSize: "2.5rem"}}></GiProgression >
                </div>
                <div className="feature-text">
                  <h4 style={{ textAlign: "left" }}>Variedad de niveles</h4>
                  <p style={{fontSize: "1.1rem"}}>Enseñamos cada uno de los niveles determinados por el 
                      Marco Común Europeo de Referencia para las Lenguas.</p>
                </div>
              </div>
              <div className="icon-text-container">
                <div className="icon-bg-feature">
                  <GrSwim aria-label="Icono de inmersión total con Linguatech" 
                    color='blue' style={{fontSize: "2.5rem"}}></GrSwim>
                </div>
                <div className="feature-text">
                  <h4 style={{ textAlign: "left" }}>Inmersión total</h4>
                  <p style={{fontSize: "1.1rem"}}>Nuestras clases están adaptadas
                      para enseñarte a pensar en inglés.</p>
                </div>
              </div>
              <div className="icon-text-container">
                <div className="icon-bg-feature">
                  <GiArtificialHive aria-label="Icono de niveles de Linguatech" 
                    color='blue' style={{fontSize: "2.5rem"}}></GiArtificialHive>
                </div>
                <div className="feature-text">
                  <h4 style={{ textAlign: "left" }}>IA</h4>
                  <p style={{fontSize: "1.1rem"}}>Hacemos uso de los últimos algoritmos de Inteligencia
                      Artificial para ayudarte en cualquier necesidad que tengas.</p>
                </div>
              </div>
      </div>
      <h3 className="testimonials-title">Usuarios de LinguaTech</h3>
      <div className="testimonials-container">
        <div className="testimonial-card-parent" style={{marginBottom: "6rem"}}>
          <div className="testimonial-card">
            <div className="testimonial-image" style={{backgroundImage: `url(${santiagoLozanoPic})`}}></div>
            <div className="testimonial-name">Daniel Murcia</div>  
            <div className="testimonial-role">Java Developer Semi-Senior</div>  
          </div>
          <blockquote className="testimonial-card-comment">"Lo que me gusta de LinguaTech es la forma en que personalizan el contenido para los desarrolladores de software"</blockquote>
        </div>
        <div className="testimonial-card-parent" style={{marginBottom: "6rem"}}>
          <div className="testimonial-card">
            <div className="testimonial-image" style={{backgroundImage: `url(${sebastianPic})`}}></div>
            <div className="testimonial-name">Camilo Castro</div>  
            <div className="testimonial-role">Backend Developer Senior</div>  
          </div>
           <blockquote className="testimonial-card-comment">"Gracias a su enfoque, Linguatech hace que cada lección de inglés sea aplicable a mis reuniones y seguimientos diarios"</blockquote>
        </div>
        <div className="testimonial-card-parent" style={{marginBottom: "3rem"}}>
          <div className="testimonial-card">
            <div className="testimonial-image" style={{backgroundImage: `url(${davidPic})`}}></div>
            <div className="testimonial-name">Felipe Fuentes</div>  
            <div className="testimonial-role">Frontend Developer</div>  
          </div>
          <blockquote className="testimonial-card-comment">{/*"Con la metodología de Linguatech me siento preparado para aplicar a nuevas ofertas de trabajo con clientes Americanos. */}
            "Con la Inteligencia Artificial de Linguatech no me da pena equivocarme mientras practico mi inglés."</blockquote>
        </div>
      </div>
     <div id="pricing-section">
        <h2 className="pricing-title">Planes y Precios</h2>
        <div className="pricing-container">
        <div className="plan shadow">
            <div className="plan-header">Prueba Gratuita</div>
            <ul className="plan-features">
              <li className="disc"><FaCheck color='blue'  size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Especializado para programadores</li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Orientado al ámbito laboral</li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Clases individuales</li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Material personalizado</li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Preparación para entrevistas </li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Disponibilidad 24/7 </li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Sin número límite de clases</li>
            </ul>
            <div className="plan-price" style={{marginBottom: "0px"}}><b><u>1 mes sin costo</u></b></div>
            <div style={{background: "green", color: "white", borderRadius: "10px", 
              marginTop: "-1.1rem", width: "50%", margin: "auto", alignItems:"center", 
              marginBottom: "0.7rem"}}>Gratis</div>
            <button className="plan-select-button" onClick={handleShowSuscriptionModal} 
              aria-label='Suscríbete a Linguatech'>Aprende Gratis</button>
          </div>
          <div className="plan shadow">
            <div className="plan-header">Suscripción Mensual</div>
            <ul className="plan-features">
              <li className="disc"><FaCheck color='blue'  size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Especializado para programadores</li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Orientado al ámbito laboral</li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Clases individuales</li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Material personalizado</li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Preparación para entrevistas</li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Disponibilidad 24/7 </li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Sin número límite de clases</li>
            </ul>
            <div className="plan-price" style={{marginBottom: "0px"}}><b>$<u>99.900 COP</u></b></div>
            <div style={{background: "orange", color: "white", borderRadius: "10px", 
              marginTop: "-1.1rem", width: "50%", margin: "auto", alignItems:"center", 
              marginBottom: "0.7rem"}}>Más Popular</div>
            <button className="plan-select-button" onClick={handleShowSuscriptionModal} 
              aria-label='Suscríbete a Linguatech'>Suscribete</button>
          </div>
          <div className="plan shadow">
            <div className="plan-header">Suscripción 6 Meses</div>
            <ul className="plan-features">
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Especializado para programadores</li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px"}}/> Orientado al ámbito laboral</li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Clases individuales</li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Material personalizado</li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Preparación para entrevistas</li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Disponibilidad 24/7 </li>
              <li className="disc"><FaCheck color='blue' size='14px'
                style={{background: "white", borderRadius: "40px", padding: "2px", marginTop: "-3px"}}/> Sin número límite de clases</li>
            </ul>
            <div className="plan-price" style={{marginTop: "0rem"}}><b>$ <u>399.900 COP</u></b></div>
            <div style={{background: "red", color: "white", borderRadius: "10px", 
              marginTop: "-1.1rem", width: "50%", margin: "auto", alignItems:"center", 
              marginBottom: "0.7rem"}}>Ahorra un 33.33%</div>
            <button className="plan-select-button" onClick={handleShowSuscriptionModal} 
              aria-label='Suscríbete a Linguatech'>Suscribete</button>
          </div>
        </div>
      </div>
      <div className="Conviction">
        <h2 style={{marginBottom: "3rem", marginTop: "4rem"}}>El Inglés te abre puertas a proyectos globales y multiplica tus ingresos.</h2>
        <h2 style={{marginBottom: "3rem"}}>¡Empieza hoy y expande tus horizontes!</h2>
        <button className="App-button" style={{marginBottom: "4rem", marginTop: "-2rem"}} 
          onClick={scrollToSuscriptionSection} aria-label='Suscríbete a Linguatech'>
          Aprende Gratis
        </button>
      </div>
      <div className="author-note-section">
        <p className="author-note">Muchos programadores se ven obligados a renunciar a
        oportunidades de trabajo remoto con clientes Estadounidenses por <b>falta de dominio del inglés.</b> <br></br> 
        Cohibiendosen de esta manera de ser parte de proyectos internacionales 
        con remuneracion en dolares. <br></br>
        <b>Por eso construimos Linguatech</b>, para brindar a los desarrolladores la ruta más rápida para aprender inglés. 
        En el último año hemos ayudado a más de 800 desarrolladores de software a alcanzar su meta
        de dominar el idioma. <b>Me encantaría que probaras nuestra metodología.</b> </p>
        <div className="author-profile">
          <img src={fotoFounder} alt="Santiago Lozano - Fundador de Linguatech" />
          <p><b>Santiago Lozano </b><br></br> Fundador de Linguatech</p>
        </div>
      </div>
      <footer className="footer-container">
        <div className="footer-left">
          <a>
            <img src={linguatechLogoBlueImage} alt="Linguatech" className="Logo" />
          </a>
        </div>
        <div className="footer-right">
          <h3>Contáctanos</h3>
          <a href="tel:+573211235656">(+57) 312 655 0636</a>
          <address></address>
          <a href="mailto:atencionalcliente@linguatech.com">atencionalcliente@linguatech.com</a>
        </div>
      </footer>
      <Modal show={showSuscriptionModal} onHide={handleCloseSuscriptionModal} centered>
        <Modal.Header>
          <img src={linguatechLogoBlueImage} alt="Linguatech" style={{width: "200px", margin: "auto"}}/>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="suscribeForm.controlName">
              <Form.Control
                type="text"
                placeholder="Nombre"
                autoFocus
                value={userName} 
                aria-label='Nombre de usuario Linguatech'
                onChange={(e)=> setUserName(e.target.value)}
              />
              <Form.Label style={{color: "red", display: nameIsEmpty ? 'block' : 'none'}}>Este campo es obligatorio</Form.Label>
              <Form.Label style={{color: "red", display: nameIsIncorrect ? 'block' : 'none'}}>Nombre inválido</Form.Label>
            </Form.Group>
            <Form.Group className="mb-3" controlId="suscribeForm.controlEmail">
              <Form.Control
                type="email"
                placeholder="Email"
                value={userEmail} 
                aria-label='Email de usuario Linguatech'
                onChange={(e)=> setUserEmail(e.target.value)}
              />
              <Form.Label style={{color: "red", display: emailIsEmpty ? 'block' : 'none'}}>Este campo es obligatorio</Form.Label>
              <Form.Label style={{color: "red", display: emailIsIncorrect ? 'block' : 'none'}}>Email inválido</Form.Label>
              <Form.Label style={{color: "red", display: emailExisting ? 'block' : 'none'}}>Email asociado a una cuenta existente</Form.Label>
            </Form.Group>
            <Form.Group className="mb-3" controlId="suscribeForm.controlPhone">
              <Form.Control
                type="tel"
                placeholder="Teléfono"
                value={userPhone} 
                aria-label='Teléfono de usuario Linguatech'
                onChange={(e)=> setUserPhone(e.target.value)}
              />
              <Form.Label style={{color: "red", display: phoneIsEmpty ? 'block' : 'none'}}>Este campo es obligatorio</Form.Label>
              <Form.Label style={{color: "red", display: phoneIsIncorrect ? 'block' : 'none'}}>Teléfono inválido</Form.Label>
              <Form.Label style={{color: "red", display: phoneExisting ? 'block' : 'none'}}>Teléfono asociado a una cuenta existente</Form.Label>
            </Form.Group>
            <Form.Group className="mb-4" controlId="suscribeForm.controlPassword">
              <div className='d-flex'>
                <Form.Control
                  style={{width: "95%"}}
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Contraseña"
                  value={userPassword} 
                  aria-label='Contraseña de usuario Linguatech'
                  onChange={(e)=> setUserPassword(e.target.value)}
                />
                <a style={{marginLeft: "1rem", marginTop: "0.4rem", cursor: "pointer"}} 
                  onClick={() => setPasswordVisible(!passwordVisible)}>
                  <VscEye size="1.8rem" color='gray'/>
                </a>
              </div>
              <Form.Label style={{color: "red", display: passwordIsEmpty ? 'block' : 'none'}}>Este campo es obligatorio</Form.Label>
              <Form.Label style={{color: "red", display: passwordIsIncorrect ? 'block' : 'none'}}>La contraseña debe tener Mayusculas, Minusculas, Números y más de 5 carácteres</Form.Label>
            </Form.Group>
            <Form.Group as={Row} controlId="suscribeForm.controlCheckPolitics">
              <Col xs="2">
                <Form.Check 
                  type="switch"
                  id="custom-switch"
                  style={{width: "2rem", borderColor:"red"}}
                  checked={checkedPersonalInformationPolitic}
                  aria-label='Politica de tratamiento de datos Linguatech'
                  onChange={() => setCheckedPersonalInformationPolitic(!checkedPersonalInformationPolitic)}
                />
              </Col>
              <Col xs="10" className='check-pivacy-policy'>
                Acepto la<Button variant="link" onClick={handleShowPersonalInformationPoliticModal} style={{marginTop: "-0.3rem", marginLeft: "-0.5rem"}}>Política de Privacidad</Button>
              </Col>
              <Form.Label style={{color: "red", display: politicIsEmpty ? 'block' : 'none', marginTop: "-0.5rem"}}>Aceptar la política es necesario</Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>          
        <Button className="App-button" 
          style={{backgroundColor: "#2f63dc", color: "white", width: "100%", textAlign: "center"}}
          onClick={handleSubmitUserData} aria-label='Suscríbete a Linguatech'>
          Suscribete
        </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPersonalInformationPoliticModal} 
        onHide={handleClosPersonalInformationPoliticModal} centered>
        <Modal.Header closeButton>
            <Modal.Title>Privacidad y Protección de Datos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <b>Introducción</b><br></br>
            En LinguaTech, valoramos la confianza que nuestros usuarios depositan en nosotros al compartir su información personal. Nos comprometemos a proteger y respetar su privacidad. <br/> Esta Política de Privacidad y Protección de Datos Personales establece las bases sobre las cuales cualquier dato personal que recopilamos de usted, o que usted nos proporciona, será procesado por nosotros.
            <br/> <br/>
            <b>1. Recolección de Información.</b> <br/>
            Al registrarse en LinguaTech, usted nos proporciona datos personales que incluyen, pero no se limitan a, su nombre, dirección de correo electrónico, número de teléfono y contraseña. Estos datos son esenciales para proporcionarle nuestros servicios.
            <br/> <br/>
            <b>2. Uso de la Información.</b> <br/>
            Utilizamos su información personal con el fin de: <br/>

            - Crear y gestionar su cuenta en LinguaTech. <br/>
            - Comunicarnos con usted en relación con nuestros servicios. <br/>
            - Mejorar y personalizar su experiencia con nuestros servicios. <br/>
            - Realizar análisis de datos y actividades de marketing, siempre dentro del marco legal.
            <br/> <br/>
            <b>3. Almacenamiento y Seguridad de la Información.</b> <br/>
            Sus datos personales se almacenan de forma segura en la infraestructura de Microsoft Azure y Amazon Web Services, garantizando la confidencialidad e integridad de la información mediante prácticas de seguridad de vanguardia.
            <br/> <br/>
            <b>4. Derechos del Usuario.</b><br/>
            Como usuario de LinguaTech, usted tiene derecho a: <br/>

            - Acceder y obtener una copia de sus datos personales.  <br/>
            - Solicitar la actualización o eliminación de sus datos personales. <br/>
            - Limitar u oponerse al procesamiento de sus datos.  
            
            <br/> <br/>
            <b>5. Consentimiento y Aceptación de la Política.</b><br/>
            El uso de nuestros servicios requiere su aceptación expresa de esta Política, lo cual se realiza a través de un mecanismo de checkbox en nuestro formulario de registro, que en caso de ser activado o seleccionado, se asume que se ha leído y aceptado nuestra Política. Un enlace a esta Política se proporcionará para su revisión detallada.

            <br/> <br/>
            <b>6. Modificaciones a la Política. </b><br/>
            LinguaTech se reserva el derecho de modificar esta Política en cualquier momento. Cualquier cambio será notificado a través de nuestro sitio web o por correo electrónico, asegurando que nuestros usuarios estén siempre informados sobre cómo protegemos su información personal.

            <br/> <br/>
            <b>7. Legislación y Cumplimiento. </b><br/>
            Esta Política se rige por las leyes de la República de Colombia y cumple con todas las normativas pertinentes en materia de protección de datos personales.

            <br/> <br/>
            <b>8. Contacto.</b><br/>
            Para cualquier pregunta o inquietud relacionada con esta Política o el tratamiento de sus datos personales, por favor póngase en contacto con nosotros a través del correo electrónico santtiagolozano@gmail.com, donde nuestro equipo estará listo para asistirle.

            <br/> <br/>
            <b>9. Aceptación de Términos.</b><br/>
            Al utilizar los servicios de LinguaTech, usted declara haber leído, entendido y aceptado los términos establecidos en esta Política. Si no está de acuerdo con alguno de los términos o condiciones, le solicitamos abstenerse de utilizar nuestros servicios.

            <br/> <br/>
            <b>10. Vigencia.</b><br/>
            Esta Política es efectiva a partir de 01/16/2024 y permanecerá vigente hasta que sea reemplazada por una versión actualizada.
        </Modal.Body>
        <Modal.Footer>          
        <Button className="App-button" 
          style={{backgroundColor: "#2f63dc", color: "white", width: "100%", textAlign: "center"}}
          onClick={aceptPersonalInformationPolitic}>
          Aceptar política
        </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSuscriptionForgivenesssModal} 
        onHide={() => setShowSuscriptionForgivenesssModal(false)} centered>
        <Modal.Body style={{padding: "2rem", fontFamily: "sans-serif"}}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img src={unavailableSystemImage} style={{width: "15rem", height: "15rem"}} alt="unavailableSystemImage"/>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
            <h2 style={{textAlign: "center"}}>¡Gracias por tu interés en unirte a nosotros! </h2>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
            <p style={{fontSize: "1.1rem", textAlign: "justify"}}>Actualmente estamos experimentando una gran cantidad de tráfico, lo que puede causar demoras. 
              Nuestro equipo está trabajando para resolverlo y nos pondremos en contacto contigo dentro de las próximas 24 horas para completar tu suscripción. 
              </p>             
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
            <p style={{fontSize: "1.1rem", fontWeight: "bold"}}> Apreciamos tu paciencia y comprensión</p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showSuscriptionFailedModal} 
        onHide={() => setShowSuscriptionFailedModal(false)} centered>
        <Modal.Body style={{padding: "2rem", fontFamily: "sans-serif"}}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img src={unavailableSystemImage} style={{width: "15rem", height: "15rem"}} alt="unavailableSystemImage"/>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
            <h2 style={{textAlign: "center"}}>¡Lo sentimos! </h2>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
            <p style={{fontSize: "1.1rem", textAlign: "justify"}}>Actualmente estamos experimentando algunos problemas. 
              Nuestro equipo está trabajando para resolverlo. Por favor, intenta más tarde.
            </p>             
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
            <p style={{fontSize: "1.1rem", fontWeight: "bold"}}> Apreciamos tu paciencia y comprensión</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>

  );
}

export default App;
